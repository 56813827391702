<template>
  <router-view />
  <div class="container">
    <div class="home-title">What would you like to do?</div>
    <div class="home-buttons">
      <component
        :is="button.href ? 'a' : 'div'"
        v-for="(button, index) in buttons"
        :key="`home-button-${index}`"
        class="home-buttons-item"
        @click="button.path ? $router.push({ path: button.path }) : ''"
        :target="button.href ? '_blank' : null"
        :href="button.href"
      >
        <div class="home-buttons-item__avatar">
          <inline-svg
            :src="require(`@/assets/icons/${button.icon}.svg`)"
            :aria-label="button.label"
          />
        </div>
        <div class="home-buttons-item__label">
          {{ button.label }}
        </div>
      </component>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    InlineSvg,
  },
  computed: {
    ...mapGetters(["clubId"]),
    baseURL() {
      return process.env.VUE_APP_BASE_URL;
    },
    buttons() {
      return [
        {
          icon: "klubba-app",
          label: "Open Klubba App",
          href: `${this.baseURL}/${this.clubId}`,
        },
        { icon: "add-subscriber", label: "Add a subscriber", path: "/subscribers/create" },
        {
          icon: "add-subscription-product",
          label: "Add a subscription product",
          path: "/subscriptions",
        },
        { icon: "send-message", label: "Send a message to subscribers", path: "/subscribers" },
        { icon: "request-payment", label: "Request payment", path: "/subscribers" },
        { icon: "edit-club-questions", label: "Edit my Club Questions", path: "/settings" },
        { icon: "apply-credit-refund", label: "Apply a credit or refund", path: "/subscribers" },
        { icon: "export-data", label: "Export data", path: "/subscribers" },
        { icon: "support", label: "Get support", path: "/customers-create-subscriber" },
      ];
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 72px;
  padding-bottom: 72px;
}

.modal-height {
  height: 436px;
}

.home-title {
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #171d33;
}

.home-buttons {
  display: grid;
  max-width: 730px;
  margin: 0 auto;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}

.home-buttons-item {
  cursor: pointer;
  height: 180px;
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.home-buttons-item__avatar {
  margin-bottom: 22px;
  width: 40px;
  height: 40px;
}

.home-buttons-item__avatar svg {
  width: 100%;
  height: 100%;
}

.home-buttons-item__label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #3b414b;
  max-width: 300px;
}
</style>
